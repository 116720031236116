<template>
  <div class="page-catalog__sorting-modal">
    <div class="page-catalog__sorting-container">
      <div class="page-catalog__sorting-title">
        {{ $t("whichOne") }}
      </div>
      <div class="page-catalog__sorting-wrapper">
        <div
          v-for="sort in sorts"
          :key="sort.label"
          class="page-catalog__sorting-item"
        >
          <input
            type="checkbox"
            :id="sort.label"
            name="sortBy"
            v-model="sort.isChecked"
            @change="checkSort(sort)"
          />
          <label class="filter-label" :for="sort.label">
            {{ sort.label }}
          </label>
        </div>
      </div>
      <button class="page-catalog__sorting-show" @click="sortingBtn">
        {{ $t("Show") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { actionTypes } from "@/store/modules/catalog";

export default {
  name: "DiscountSort",
  data() {
    return {
      selectedSort: null,
      isChecked: false,
      sort: null,
      sorts: [
        {
          field: "createdAt",
          sortType: "desc",
          label: this.$t("byNewest"),
          isChecked: false,
        },
        {
          field: "final_price",
          sortType: "asc",
          label: this.$t("priceAscending"),
          isChecked: false,
        },
        {
          field: "final_price",
          sortType: "desc",
          label: this.$t("priceDescending"),
          isChecked: false,
        },
        {
          field: "discount",
          sortType: "desc",
          label: this.$t("discountDescending"),
          isChecked: false,
        },
        {
          field: "discount",
          sortType: "asc",
          label: this.$t("discountAscending"),
          isChecked: false,
        },
      ],
    };
  },
  methods: {
    ...mapActions("catalogModule", { changeSortBy: actionTypes.changeSortBy }),
    checkSort(item) {
      if (item.id === this.selectedSort?.id) {
        this.isChecked = true;
      }
      this.selectedSort = item;
      this.changeSortBy(item);
      this.sort = item;
      this.sorts.forEach((e) => {
        e.isChecked = false;
      });
      item.isChecked = true;
    },
    sortingBtn() {
      this.$emit("sort-start");
      this.$emit("sort-btn");
      this.$emit("sort-obj", this.sort);
    },
  },
};
</script>
